export const GET_LIST_LANG = 'GET_LIST_LANG';
export const SET_LIST_LANG = 'SET_LIST_LANG';
export const INSERT_LANG = 'INSERT_LANG';
export const UPDATE_LANG = 'UPDATE_LANG';
export const DELETE_LANG = 'DELETE_LANG';
export const GET_LIST_TERMS = 'GET_LIST_TERMS';
export const SET_LIST_TERMS = 'SET_LIST_TERMS';
export const SAVE_TERMS = 'SAVE_TERMS';
export const SET_CUR_LANG = 'SET_CUR_LANG';
export const EXPORT_EXCEL = 'EXPORT_EXCEL';
export const GET_IMPORT_EXCEL = 'GET_IMPORT_EXCEL';
export const SET_IMPORT_EXCEL = 'SET_IMPORT_EXCEL';
