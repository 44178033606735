import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  LockOutlined,
  PhoneOutlined,
  UserOutlined,
  GroupOutlined,
  TeamOutlined,
  GlobalOutlined,
} from '@ant-design/icons';
import {
  Button,
  Form,
  Input,
  Alert,
  Select,
  Row,
  Col,
  Checkbox,
  Modal,
  Drawer,
  notification,
  DatePicker,
} from 'antd';
import { showAuthMessage } from 'redux/actions/Auth';
import { useHistory } from 'react-router-dom';
import JwtAuthService from 'services/JwtAuthService';
import fetch from 'auth/FetchInterceptor';
import TreeOrg from 'components/shared-components/Tree/TreeOrg';
import { APPR_STATUS, ROLE_INFO } from 'configs/CommonCode';
import * as actions from 'redux/actions/Account';
import ChangePwd from './ChangePwd';
import moment from 'moment';
import { useIntl } from 'react-intl';

var callingCountries = require('country-data').callingCountries;

const UserDetail = ({ close, visible, isShow }) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const treeOrg = useSelector((state) => state.org.treeOrg) || [];
  const selectedUser = useSelector((state) => state.account.selectedUser) || 0;
  const userDetail = useSelector((state) => state.account.userDetail) || {};

  const [form] = Form.useForm();
  const { Option } = Select;
  let history = useHistory();
  const [countryList, setCountryList] = useState([]);
  const [langList, setLangList] = useState([]);
  const [visibleUnit, setVisibleUnit] = useState(false);
  const [unitCd, setUnitCd] = useState('');
  const [selectCountry, setSelectCountry] = useState('');
  const [loading, setLoading] = useState(false);
  const [recvSms, setRecvSms] = useState(false);
  const [visiblePwd, setVisiblePwd] = useState(false);
  const [isShowContent, setIsShowContent] = useState(false);

  const rules = {
    password: [
      {
        // required: true,
        //message: 'Please input your password',
        message: formatMessage({ id: 'message.account.0000001' }),
      },
    ],
    confirm: [
      {
        // required: true,
        //message: 'Please confirm your password!',
        message: formatMessage({ id: 'message.account.0000002' }),
      },
      ({ getFieldValue }) => ({
        validator(rule, value) {
          if (!value || getFieldValue('password') === value) {
            return Promise.resolve();
          }
          // return Promise.reject('Passwords do not match!');
          return Promise.reject(
            formatMessage({ id: 'message.account.0000003' })
          );
        },
      }),
    ],
    userName: [
      {
        required: true,
        //message: 'Please input your Name',
        message: formatMessage({ id: 'message.account.0000004' }),
      },
    ],
  };

  /** 사용자 정보 저장 */
  const onSave = () => {
    form
      .validateFields()
      .then((values) => {
        const data = {
          userId: values.userId,
          userNm: values.userNm,
          password: values.password,
          cellNation: selectCountry ? selectCountry.split(':')[0] : '',
          nationCd: selectCountry ? selectCountry.split(':')[1] : '',
          cellNo: values.cellNo,
          unitCd: unitCd,
          roleCd: values.roleCd,
          langCd: values.langCd,
          recvSms: recvSms ? 'Y' : 'N',
          apprStatus: values.apprStatus,
          expireDate: moment(values.expireDate).format('YYYY-MM-DD'),
        };

        setLoading(true);
        dispatch(actions.updateUser(data));
        setLoading(false);
      })
      .catch((info) => {
        console.log('Update Failed:', info);
      });
  };

  /** 국가별 전화번호 셋팅 */
  const getCallingCounty = () => {
    if (callingCountries) {
      let objCountry = [];
      callingCountries.all.forEach((item) => {
        objCountry.push({
          label: `${item.name} [${item.countryCallingCodes[0]}]`,
          value: `${item.countryCallingCodes[0]}:${item.ioc}`,
        });
      });

      /** 정렬(이름순) */
      objCountry.sort(function (a, b) {
        return a.label.localeCompare(b.label);
      });

      /** 중복제거 */
      const result = objCountry.filter((item1, idx1) => {
        return (
          objCountry.findIndex((item2, idx2) => {
            return item1.value === item2.value;
          }) === idx1
        );
      });

      setCountryList(result);
    }
  };

  /** 언어 셋팅 */
  const getLangList = () => {
    try {
      fetch({
        url: '/user/langList',
        method: 'get',
        headers: {
          'public-request': 'true',
        },
      }).then((resp) => {
        if (resp) {
          setLangList(resp.data.filter((item) => item.useYn === 'Y'));

          if (userDetail) {
            form.setFieldsValue({ langCd: userDetail.langCd });
          }
        }
      });
    } catch (e) {}
  };

  /** 조직도 선택 */
  const handleClickTree = (node) => {
    setUnitCd(node.unitCd);
    form.setFieldsValue({ unitNm: node.unitNm });
  };

  const getUserDetail = (user) => {
    dispatch(actions.getUserDetail(user));
  };

  useEffect(() => {
    if (isShow) {
      setIsShowContent(false);
    } else {
      setIsShowContent(true);
    }
  }, [isShow]);

  useEffect(() => {
    if (countryList.length === 0) {
      getCallingCounty();
    }
    getLangList();
  }, [visible]);

  useEffect(() => {
    if (selectedUser && selectedUser !== 0) getUserDetail(selectedUser);
  }, [selectedUser]);

  /** 사용자 정보 셋팅 */
  useEffect(() => {
    const {
      apprStatus,
      cellNation,
      cellNo,
      expireDate,
      langCd,
      nationCd,
      recvSms,
      roleCd,
      unitCd,
      unitNm,
      userId,
      userNm,
    } = userDetail;
    form.setFieldsValue({
      apprStatus,
      cellNo,
      expireDate: expireDate && expireDate !== '' ? moment(expireDate) : '',
      langCd,
      roleCd,
      unitNm,
      userId,
      userNm,
    });

    if (userDetail.cellNation) {
      const val = `${userDetail.cellNation}:${userDetail.nationCd}`;
      setSelectCountry(val);
      form.setFieldsValue({ country: val });
    } else {
      setSelectCountry('');
    }
    setUnitCd(userDetail.unitCd);
    setRecvSms(userDetail.recvSms === 'Y' ? true : false);
  }, [userDetail]);

  return (
    <>
      <Drawer
        width={500}
        //title="Account"
        title={formatMessage({ id: 'title.account' })}
        placement="right"
        onClose={close}
        // closable={false}
        visible={visible}
      >
        <Form
          form={form}
          layout="vertical"
          name="register-form"
          onFinish={onSave}
          scrollToFirstError
        >
          {/* <h1>Account</h1> */}
          <Form.Item
            name="userId"
            //label="ID"
            label={formatMessage({ id: 'label.account.0000001' })}
          >
            <Input
              placeholder="ID"
              disabled={true}
              prefix={<UserOutlined className="text-primary" />}
            />
          </Form.Item>
          <Form.Item
            name="password"
            // label="Password"
            label={formatMessage({ id: 'label.account.0000002' })}
            rules={rules.password}
          >
            <Input.Password
              placeholder="Password"
              readOnly={true}
              onClick={(e) => {
                console.log('eee >', e);
                setVisiblePwd(true);
              }}
              prefix={<LockOutlined className="text-primary" />}
            />
          </Form.Item>
          <Form.Item
            name="userNm"
            // label="Name"
            label={formatMessage({ id: 'label.account.0000003' })}
            rules={rules.userName}
          >
            <Input
              placeholder="Name"
              prefix={<UserOutlined className="text-primary" />}
            />
          </Form.Item>
          <Row>
            <Col span={10}>
              <Form.Item
                name="country"
                // label="Country"
                label={formatMessage({ id: 'label.account.0000004' })}
              >
                <Select
                  placeholder="select country"
                  showSearch
                  value={selectCountry}
                  options={countryList}
                  prefix={<UserOutlined className="text-primary" />}
                  optionFilterProp="children"
                  onChange={(value) => setSelectCountry(value)}
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                />
              </Form.Item>
            </Col>
            <Col span={14}>
              <Form.Item
                name="cellNo"
                // label="Phone"
                label={formatMessage({ id: 'label.account.0000005' })}
              >
                <Input
                  placeholder="Phone No."
                  prefix={<PhoneOutlined className="text-primary" />}
                />
              </Form.Item>
            </Col>
          </Row>
          <div className="mb-2">
            {/* <Form.Item name="sms" label=""> */}
            <Checkbox
              checked={recvSms}
              onChange={(e) => {
                e.target.checked ? setRecvSms(true) : setRecvSms(false);
              }}
            >
              I agree receive notifications via SMS
            </Checkbox>
            {/* </Form.Item> */}
          </div>
          <Form.Item
            name="unitNm"
            //label="Unit"
            label={formatMessage({ id: 'label.account.0000006' })}
            rules={[{ required: true }]}
          >
            <Input
              disabled={isShowContent}
              placeholder="select Unit"
              readOnly
              onClick={() => setVisibleUnit(true)}
              prefix={<TeamOutlined className="text-primary" />}
            />
          </Form.Item>
          <Form.Item
            name="roleCd"
            // label="Role"
            label={formatMessage({ id: 'label.account.0000007' })}
            rules={[{ required: true }]}
          >
            <Select placeholder="select Role" disabled={isShowContent}>
              {ROLE_INFO.map((item) => (
                <Option key={item.roleCd}>{item.roleNm}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="langCd"
            // label="Language"
            label={formatMessage({ id: 'label.account.0000008' })}
            rules={[{ required: true }]}
          >
            <Select placeholder="select Language">
              {langList.map((item) => (
                <Option
                  key={item.langCd}
                >{`${item.langNm} [${item.locCd}]`}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="apprStatus"
            // label="Status"
            label={formatMessage({ id: 'label.account.0000009' })}
            rules={[{ required: true }]}
          >
            <Select placeholder="select Status" disabled={isShowContent}>
              {APPR_STATUS.map((item) => (
                <Option key={item.status}>{item.statusNm}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="expireDate"
            //label="Expire Date"
            label={formatMessage({ id: 'label.account.0000010' })}
          >
            <DatePicker />
          </Form.Item>
          <Form.Item className="mt-4">
            <Button type="primary" htmlType="submit" block loading={loading}>
              {/* Save */ formatMessage({ id: 'btn.save' })}
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
      <Modal
        // title={'Select Unit'}
        title={formatMessage({ id: 'title.unitPopup' })}
        visible={visibleUnit}
        onCancel={() => setVisibleUnit(false)}
        onOk={() => setVisibleUnit(false)}
        okText={formatMessage({ id: 'btn.ok' })}
        cancelText={formatMessage({ id: 'btn.cancel' })}
      >
        <TreeOrg
          mode="regist"
          treeOrg={treeOrg}
          handleClickTree={(node) => handleClickTree(node)}
        />
      </Modal>
      <Modal
        // title={'Change Password'}
        title={formatMessage({ id: 'title.changePassword' })}
        visible={visiblePwd}
        onCancel={() => setVisiblePwd(false)}
        onOk={() => setVisiblePwd(false)}
        footer={null}
      >
        <ChangePwd
          userId={userDetail.userId}
          close={() => setVisiblePwd(false)}
        />
      </Modal>
    </>
  );
};

UserDetail.defaultProps = {
  isShow: false,
};

export default UserDetail;
