import {
  SET_DASHBOARD_LIST,
  SET_SELECTED_DASHBOARD,
  SET_DASHBOARD_DETAIL,
  SET_STATUS_CARD,
  SET_ERROR_CARD,
  SET_DASHBOARD_UNIT,
} from '../constants/Dashboard';

const initState = {
  dashboardDetail: {},
  dashboardUnit: 0,
  selectedDashboard: '',
  statusCard: 'Installed',
  errorCard: '',
};

const dashboard = (state = initState, action) => {
  switch (action.type) {
    case SET_SELECTED_DASHBOARD:
      return {
        ...state,
        selectedDashboard: action.data,
      };
    case SET_DASHBOARD_DETAIL:
      return {
        ...state,
        dashboardDetail: action.payload,
      };
    case SET_STATUS_CARD:
      return {
        ...state,
        statusCard: action.payload,
      };
    case SET_ERROR_CARD:
      return {
        ...state,
        errorCard: action.payload,
      };
    case SET_DASHBOARD_UNIT:
      return {
        ...state,
        dashboardUnit: action.payload,
      };
    default:
      return state;
  }
};

export default dashboard;
