import {
  GET_LIST_LANG,
  SET_LIST_LANG,
  INSERT_LANG,
  UPDATE_LANG,
  DELETE_LANG,
  GET_LIST_TERMS,
  SET_LIST_TERMS,
  SAVE_TERMS,
  SET_CUR_LANG,
  EXPORT_EXCEL,
  GET_IMPORT_EXCEL,
  SET_IMPORT_EXCEL,
} from '../constants/Lang';

export const getListLang = (data) => {
  return {
    type: GET_LIST_LANG,
    payload: data,
  };
};

export const setListLang = (data) => {
  return {
    type: SET_LIST_LANG,
    data,
  };
};

export const insertLang = (data) => {
  return {
    type: INSERT_LANG,
    payload: data,
  };
};

export const updateLang = (data) => {
  console.log('action data', data);
  return {
    type: UPDATE_LANG,
    payload: data,
  };
};

export const deleteLang = (data) => {
  return {
    type: DELETE_LANG,
    payload: data,
  };
};

export const getListTerms = (data) => {
  return {
    type: GET_LIST_TERMS,
    payload: data,
  };
};

export const setListTerms = (data) => {
  return {
    type: SET_LIST_TERMS,
    data,
  };
};

export const saveTerms = (data) => {
  console.log('saveTerms >>>', data);
  return {
    type: SAVE_TERMS,
    payload: data,
  };
};

export const setCurLang = (data) => {
  return {
    type: SET_CUR_LANG,
    data,
  };
};

export const exportExcel = (data) => {
  console.log('exportExcel >>>', data);
  return {
    type: EXPORT_EXCEL,
    payload: data,
  };
};

export const getImportExcel = (data) => {
  // console.log('importExcel >>>', data);
  return {
    type: GET_IMPORT_EXCEL,
    payload: data,
  };
};

export const setImportExcel = (data) => {
  // console.log('setImportExcel >>>', data);
  return {
    type: SET_IMPORT_EXCEL,
    data,
  };
};
