import React, { useEffect, useState, useContext } from 'react';
import {
  SettingOutlined,
  LogoutOutlined,
  UserOutlined,
  BellOutlined,
} from '@ant-design/icons';
import { Drawer, Menu, Modal, Badge } from 'antd';
import ThemeConfigurator from './ThemeConfigurator';
import { connect, useDispatch, useSelector } from 'react-redux';
import { DIR_RTL } from 'constants/ThemeConstant';
import { AUTH_TOKEN, AUTH_USER } from 'redux/constants/Auth';
import { signOut } from 'redux/actions/Auth';
import { useHistory } from 'react-router-dom';
import { AUTH_PREFIX_PATH, WEB_SOCKET_URL } from 'configs/AppConfig';
import * as actions from 'redux/actions/Account';
import * as actionsAed from 'redux/actions/Aed';
import UserDetail from 'views/app-views/setting/account/UserDetail';
import { WebSocketContext } from 'websocket/WebSocketProvider';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';

const NavPanel = (props) => {
  const dispatch = useDispatch();
  let history = useHistory();
  const { formatMessage } = useIntl();
  const isLogin = useSelector((state) => state.auth.isLogin);
  const reqCnt = useSelector((state) => state.aed.apprCnt) || 0;
  const onWsConnect = useSelector((state) => state.aed.onWsConnect) || false;
  // const ws = new WebSocket(WEB_SOCKET_URL);
  const ws = useContext(WebSocketContext);

  const [visible, setVisible] = useState(false);
  const [isLogoutVisible, setIsLogoutVisible] = useState(false);
  const [isLoginVisible, setIsLoginVisible] = useState(false);
  const [userId, setUserId] = useState('');
  const [apprCnt, setApprCnt] = useState(0);

  const auth = JSON.parse(localStorage.getItem(AUTH_USER));
  const paramUnit = {
    unitCd: auth ? auth.unitCd : 0,
  };

  const showDrawer = () => {
    // setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  /** logout */
  const handleLogout = () => {
    dispatch(signOut());
    history.push(`${AUTH_PREFIX_PATH}/login`);
  };

  const showLogoutModal = () => {
    setIsLogoutVisible(true);
  };

  const showLoginModal = () => {
    dispatch(actions.setSelectedUser(userId));
    setIsLoginVisible(true);
  };

  const handleOk = () => {
    handleLogout();
    setIsLogoutVisible(false);
  };

  const handleCancel = () => {
    setIsLogoutVisible(false);
  };

  useEffect(() => {
    /** 토큰이 없으면 로그아웃 처리 */
    const token = localStorage.getItem(AUTH_TOKEN);
    if (!token || token === '') {
      handleLogout();
    }
  }, []);

  const sendMsg = (event) => {
    let msg = '';
    if ('getApprCnt' === event) {
      msg = JSON.stringify({
        event,
        data: JSON.stringify(paramUnit),
      });
    }
    // ws.send(msg);

    ws.current.send(msg);
  };

  const onMsg = (res) => {
    console.log('onMsg res >>>>', res);
    const result = JSON.parse(res.data);
    if (result.event === 'reqApprCnt') {
      console.log('result.data.cnt >>>', result.data.cnt);
      setApprCnt(result.data.cnt);
      dispatch(actionsAed.getApprList(paramUnit));
    } else if (result.event === 'sendApprCnt') {
      sendMsg('getApprCnt');
    }
  };

  const openSocket = () => {
    // ws.onopen = () => {
    //   console.log('ws >>>>> connected!!');
    //   sendMsg('getApprCnt');
    //   ws.onmessage = function (res) {
    //     onMsg(res);
    //   };
    // };
  };

  ws.current.onmessage = (res) => {
    onMsg(res);
  };

  const goAppr = () => {
    console.log('goAppr >>>>>>>>>');
    history.push(`/app/install-confirm`);
  };

  useEffect(() => {
    const auth = JSON.parse(localStorage.getItem(AUTH_USER));
    setUserId(auth ? auth.userId : '');

    // openSocket();
  }, []);

  useEffect(() => {
    setApprCnt(reqCnt);
  }, [reqCnt]);

  useEffect(() => {
    if (onWsConnect) sendMsg('getApprCnt');
  }, [onWsConnect]);

  return (
    <>
      <Menu mode="horizontal">
        <Menu.Item onClick={() => showLogoutModal()}>
          <LogoutOutlined className="nav-icon mr-0" />
        </Menu.Item>
        <Menu.Item onClick={() => goAppr()}>
          {/* <SettingOutlined className="nav-icon mr-0" /> */}
          <Badge count={apprCnt}>
            <BellOutlined className="nav-icon mr-0" />
          </Badge>
        </Menu.Item>
        <Menu.Item onClick={() => showLoginModal()}>
          <UserOutlined className="nav-icon mr-0" />
          <span class="pl-2">{userId}</span>
        </Menu.Item>
      </Menu>
      <Drawer
        title="Theme Config"
        placement={props.direction === DIR_RTL ? 'left' : 'right'}
        width={350}
        onClose={onClose}
        visible={visible}
      >
        <ThemeConfigurator />
      </Drawer>
      <Modal
        title="Logout"
        visible={isLogoutVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>{formatMessage({ id: 'message.account.0000012' })}</p>
      </Modal>
      <UserDetail
        // data={selectedUser}
        visible={isLoginVisible}
        close={() => {
          setIsLoginVisible(false);
        }}
      />
    </>
  );
};

const mapStateToProps = ({ theme }) => {
  const { locale } = theme;
  return { locale };
};

export default connect(mapStateToProps, null)(NavPanel);
