export const GET_AED_LIST = 'GET_AED_LIST';
export const SET_AED_LIST = 'SET_AED_LIST';
export const SET_SELECTED_AED = 'SET_SELECTED_AED';
export const GET_AED_DETAIL = 'GET_AED_DETAIL';
export const SET_AED_DETAIL = 'SET_AED_DETAIL';
export const INSERT_AED = 'INSERT_AED';
export const UPDATE_AED = 'UPDATE_AED';
export const DELETE_AED = 'DELETE_AED';
export const GET_APPR_LIST = 'GET_APPR_LIST';
export const SET_APPR_LIST = 'SET_APPR_LIST';
export const SET_SELECTED_APPR = 'SET_SELECTED_APPR';
export const GET_APPR_DETAIL = 'GET_APPR_DETAIL';
export const SET_APPR_DETAIL = 'SET_APPR_DETAIL';
export const SAVE_APPR = 'SAVE_APPR';
export const SET_APPR_CNT = 'SET_APPR_CNT';
export const ON_WS_CONNECT = 'ON_WS_CONNECT';
export const GET_AED_HIS_LIST = 'GET_AED_HIS_LIST';
export const SET_AED_HIS_LIST = 'SET_AED_HIS_LIST';
export const GET_DEVICE_STATUS_CNT = 'GET_DEVICE_STATUS_CNT';
export const SET_DEVICE_STATUS_CNT = 'SET_DEVICE_STATUS_CNT';
export const GET_UPCOMMING_LIST = 'GET_UPCOMMING_LIST';
export const SET_UPCOMMING_LIST = 'SET_UPCOMMING_LIST';
export const GET_STATUS_NOW = 'GET_STATUS_NOW';
export const SET_STATUS_NOW = 'SET_STATUS_NOW';
export const GET_STATUS_MONTHLY = 'GET_STATUS_MONTHLY';
export const SET_STATUS_MONTHLY = 'SET_STATUS_MONTHLY';
export const GET_IMPORT_EXCEL_AED = 'GET_IMPORT_EXCEL_AED';
