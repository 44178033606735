import {
  SET_USER_LIST,
  SET_SELECTED_USER,
  SET_USER_DETAIL,
} from '../constants/Account';

const initState = {
  userList: [],
  userDetail: {},
  selectedUser: '',
};

const account = (state = initState, action) => {
  switch (action.type) {
    case SET_USER_LIST:
      return {
        ...state,
        userList: action.data,
      };
    case SET_SELECTED_USER:
      return {
        ...state,
        selectedUser: action.data,
      };
    case SET_USER_DETAIL:
      return {
        ...state,
        userDetail: action.payload,
      };
    default:
      return state;
  }
};

export default account;
