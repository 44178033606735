import { all } from 'redux-saga/effects';
import Auth from './Auth';
import Lang from './Lang';
import Org from './Org';
import Account from './Account';
import Pad from './Pad';
import Battery from './Battery';
import Aed from './Aed';
import Event from './Event';
import Dashboard from './Dashboard';

export default function* rootSaga(getState) {
  yield all([
    Auth(),
    Lang(),
    Org(),
    Account(),
    Pad(),
    Battery(),
    Aed(),
    Event(),
    Dashboard(),
  ]);
}
