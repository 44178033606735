import {
  DashboardOutlined,
  UnorderedListOutlined,
  WifiOutlined,
  AuditOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { APP_PREFIX_PATH } from 'configs/AppConfig';

const dashBoardNavTree = [
  // {
  //   key: 'home',
  //   path: `${APP_PREFIX_PATH}/home`,
  //   title: 'home',
  //   icon: DashboardOutlined,
  //   breadcrumb: false,
  //   authView: false,
  //   submenu: [],
  // },
  {
    key: 'device-list',
    path: `${APP_PREFIX_PATH}/device-list`,
    title: 'Device List',
    icon: UnorderedListOutlined,
    breadcrumb: true,
    authView: false,
    submenu: [],
  },
  {
    key: 'event-list',
    path: `${APP_PREFIX_PATH}/event-list`,
    title: 'Event List',
    icon: WifiOutlined,
    breadcrumb: true,
    authView: false,
    submenu: [],
  },
  {
    key: 'install-confirm',
    path: `${APP_PREFIX_PATH}/install-confirm`,
    title: 'Install Confirmation',
    icon: AuditOutlined,
    breadcrumb: true,
    authView: true,
    submenu: [],
  },
  {
    key: 'setting',
    path: `${APP_PREFIX_PATH}/setting`,
    title: 'Setting',
    icon: SettingOutlined,
    breadcrumb: true,
    authView: true,
    submenu: [
      {
        key: 'organization',
        path: `${APP_PREFIX_PATH}/setting/organization`,
        title: 'Organization',
        icon: '',
        breadcrumb: true,
        submenu: [],
      },
      {
        key: 'account',
        path: `${APP_PREFIX_PATH}/setting/account`,
        title: 'Account',
        icon: '',
        breadcrumb: true,
        submenu: [],
      },
      {
        key: 'screen-layout',
        path: `${APP_PREFIX_PATH}/setting/screen-layout`,
        title: 'Screen Layout',
        icon: '',
        breadcrumb: true,
        submenu: [],
      },
      {
        key: 'device-aed',
        path: `${APP_PREFIX_PATH}/setting/device-aed`,
        title: 'Device-AED',
        icon: '',
        breadcrumb: true,
        submenu: [],
      },
      {
        key: 'device-battery',
        path: `${APP_PREFIX_PATH}/setting/device-battery`,
        title: 'Device-Battery',
        icon: '',
        breadcrumb: true,
        submenu: [],
      },
      {
        key: 'device-pad',
        path: `${APP_PREFIX_PATH}/setting/device-pad`,
        title: 'Device-Pad',
        icon: '',
        breadcrumb: true,
        submenu: [],
      },
      {
        key: 'language',
        path: `${APP_PREFIX_PATH}/setting/language`,
        title: 'Language',
        icon: '',
        breadcrumb: true,
        submenu: [],
      },
    ],
  },
];

const navigationConfig = [...dashBoardNavTree];

export default navigationConfig;
