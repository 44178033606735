import fetch from 'auth/FetchInterceptor';
import { API_BASE_URL } from 'configs/AppConfig';

const ApiService = {};

ApiService.get = function (url, data) {
  return fetch({
    url: API_BASE_URL + url,
    method: 'get',
    headers: {
      'public-request': 'true',
    },
    data: data,
  });
};

ApiService.post = function (url, data) {
  return fetch({
    url: API_BASE_URL + url,
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: data,
  });
};

ApiService.patch = function (url, data) {
  return fetch({
    url: API_BASE_URL + url,
    method: 'patch',
    headers: {
      'public-request': 'true',
    },
    data: data,
  });
};

ApiService.delete = function (url, data) {
  return fetch({
    url: API_BASE_URL + url,
    method: 'delete',
    headers: {
      'public-request': 'true',
    },
    data: data,
  });
};

ApiService.fileUpload = function (data) {
  return fetch({
    url: '/uploads',
    method: 'post',
    data: data,
  });
};

ApiService.fileDown = function (url, data) {
  return fetch({
    url: API_BASE_URL + url,
    method: 'post',
    responseType: 'blob',
    data: data,
  });
};

ApiService.excelFileUpload = function (url, data) {
  return fetch({
    url: API_BASE_URL + url,
    method: 'post',
    data: data,
  });
};

export default ApiService;
