import {
  SET_TREE_ORG,
  SET_SELECTED_ORG,
  SET_DETAIL_ORG,
} from "../constants/Org";

const initState = {
  treeOrg: [],
  selectedOrg: { unitCd: 0, unitNm: "" },
  detailOrg: {},
};

const org = (state = initState, action) => {
  switch (action.type) {
    case SET_TREE_ORG:
      return {
        ...state,
        treeOrg: action.data,
      };
    case SET_SELECTED_ORG:
      return {
        ...state,
        selectedOrg: action.data,
      };
    case SET_DETAIL_ORG:
      return {
        ...state,
        detailOrg: action.data,
      };
    default:
      return state;
  }
};

export default org;
