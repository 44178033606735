import {
  SET_SELECTED_DASHBOARD,
  GET_DASHBOARD_DETAIL,
  SET_DASHBOARD_DETAIL,
  INSERT_DASHBOARD,
  UPDATE_DASHBOARD,
  DELETE_DASHBOARD,
  SET_STATUS_CARD,
  SET_ERROR_CARD,
  SET_DASHBOARD_UNIT,
} from '../constants/Dashboard';

export const setSelectedDashBoard = (data) => {
  return {
    type: SET_SELECTED_DASHBOARD,
    data,
  };
};

export const getDashBoardDetail = (data) => {
  return {
    type: GET_DASHBOARD_DETAIL,
    payload: data,
  };
};

export const setDashBoardDetail = (data) => {
  return {
    type: SET_DASHBOARD_DETAIL,
    payload: data,
  };
};

export const insertDashBoard = (data) => {
  return {
    type: INSERT_DASHBOARD,
    payload: data,
  };
};

export const updateDashBoard = (data) => {
  return {
    type: UPDATE_DASHBOARD,
    payload: data,
  };
};

export const deleteDashBoard = (data) => {
  return {
    type: DELETE_DASHBOARD,
    payload: data,
  };
};

export const setStatusCard = (data) => {
  return {
    type: SET_STATUS_CARD,
    payload: data,
  };
};

export const setErrorCard = (data) => {
  return {
    type: SET_ERROR_CARD,
    payload: data,
  };
};

export const setDashboardUnit = (data) => {
  return {
    type: SET_DASHBOARD_UNIT,
    payload: data,
  };
};
