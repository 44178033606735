import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import { Modal } from 'antd';
import {
  GET_AED_LIST,
  SET_AED_LIST,
  GET_AED_DETAIL,
  INSERT_AED,
  UPDATE_AED,
  DELETE_AED,
  GET_APPR_LIST,
  SET_APPR_LIST,
  GET_APPR_DETAIL,
  SAVE_APPR,
  GET_AED_HIS_LIST,
  SET_AED_HIS_LIST,
  GET_DEVICE_STATUS_CNT,
  SET_DEVICE_STATUS_CNT,
  GET_UPCOMMING_LIST,
  SET_UPCOMMING_LIST,
  GET_STATUS_NOW,
  SET_STATUS_NOW,
  GET_STATUS_MONTHLY,
  SET_STATUS_MONTHLY,
  GET_IMPORT_EXCEL_AED,
} from '../constants/Aed';
import {
  getAedList,
  setAedDetail,
  getApprList,
  setApprDetail,
} from '../actions/Aed';
import { showAuthMessage } from '../actions/Auth';
import { AUTH_USER } from 'redux/constants/Auth';
import { appIntl } from 'IntlGlobalProvider';

import ApiService from 'services/ApiService';
import utils from 'utils';

export function* callAedList() {
  yield takeEvery(GET_AED_LIST, function* ({ payload }) {
    try {
      console.log('callAedList payload >>>>', payload);
      const mode = payload ? payload.mode : '';
      const url = 'device' === mode ? '/aed/deviceList' : '/aed/list';

      const { unitCd } = utils.userInfo();
      const params = { ...payload, unitCd };
      console.log('GET_AED_LIST params >>>>', url, params);
      const list = yield call(ApiService.post, url, params);
      if (list) {
        yield put({ type: SET_AED_LIST, data: list.data });
      } else {
        yield put({ type: SET_AED_LIST, data: [] });
      }
    } catch (err) {
      yield put(showAuthMessage(err));
    }
  });
}

export function* callAedDetail() {
  yield takeEvery(GET_AED_DETAIL, function* ({ payload }) {
    const url = `/aed/list/${payload}`;

    try {
      const list = yield call(ApiService.get, url, {});
      if (list) {
        yield put(setAedDetail(list.data));
      }
    } catch (err) {
      yield put(showAuthMessage(err));
    }
  });
}

export function* callInsertAed() {
  yield takeEvery(INSERT_AED, function* ({ payload }) {
    try {
      const url = `/aed`;
      const res = yield call(ApiService.post, url, payload);
      console.log('res >>>', res);
      if (res.resultCode === 201) {
        utils.openNotificationWithIcon(
          'success',
          appIntl().formatMessage({ id: 'message.account.0000009' })
        );
        yield put(getAedList());
      }
    } catch (err) {
      yield put(showAuthMessage(err));
    }
  });
}

export function* callUpdateAed() {
  yield takeEvery(UPDATE_AED, function* ({ payload }) {
    try {
      const { aedSeq } = payload;
      const url = `/aed/${aedSeq}`;
      const res = yield call(ApiService.patch, url, payload);
      console.log('res >>>', res);
      if (res.resultCode === 201) {
        utils.openNotificationWithIcon(
          'success',
          appIntl().formatMessage({ id: 'message.account.0000009' })
        );
        yield put(getAedList());
      }
    } catch (err) {
      yield put(showAuthMessage(err));
    }
  });
}

export function* callDeleteAed() {
  yield takeEvery(DELETE_AED, function* ({ payload }) {
    try {
      const { aedSeq } = payload;
      const url = '/aed/' + aedSeq;
      const params = {};
      const res = yield call(ApiService.delete, url, params);
      if (res.resultCode === 201) {
        utils.openNotificationWithIcon(
          'success',
          appIntl().formatMessage({ id: 'message.account.0000013' })
        );
        yield put(getAedList());
      }
    } catch (err) {
      yield put(showAuthMessage(err));
    }
  });
}

export function* callApprList() {
  yield takeEvery(GET_APPR_LIST, function* ({ payload }) {
    const url = '/aed/appr/list';
    console.log('GET_APPR_LIST >>>> ', payload);
    try {
      const list = yield call(ApiService.post, url, payload);
      if (list) {
        yield put({ type: SET_APPR_LIST, data: list.data });
      }
    } catch (err) {
      yield put(showAuthMessage(err));
    }
  });
}

export function* callApprDetail() {
  yield takeEvery(GET_APPR_DETAIL, function* ({ payload }) {
    const url = `/aed/appr/list/${payload}`;

    try {
      const list = yield call(ApiService.get, url, {});
      if (list) {
        yield put(setApprDetail(list.data));
      }
    } catch (err) {
      yield put(showAuthMessage(err));
    }
  });
}

export function* callSaveAppr() {
  yield takeEvery(SAVE_APPR, function* ({ payload }) {
    try {
      const url = `/aed/appr/save`;
      const res = yield call(ApiService.post, url, payload);
      console.log('res >>>', res);
      if (res.resultCode === 201) {
        utils.openNotificationWithIcon(
          'success',
          appIntl().formatMessage({ id: 'message.account.0000009' })
        );

        const auth = JSON.parse(localStorage.getItem(AUTH_USER));
        const params = {
          unitCd: auth.unitCd,
        };
        yield put(getApprList(params));
      }
    } catch (err) {
      console.error('msg err >>>>>', err);
      yield put(showAuthMessage(err));
    }
  });
}

export function* callAedHisList() {
  yield takeEvery(GET_AED_HIS_LIST, function* ({ payload }) {
    try {
      const { aedSeq } = payload;
      const url = `/aed/hist/list/${aedSeq}`;
      const list = yield call(ApiService.get, url, {});
      if (list) {
        yield put({ type: SET_AED_HIS_LIST, data: list.data });
      }
    } catch (err) {
      yield put(showAuthMessage(err));
    }
  });
}

export function* callDeviceStatusCnt() {
  yield takeEvery(GET_DEVICE_STATUS_CNT, function* ({ payload }) {
    try {
      const url = `/aed/statusCount`;
      const { unitCd } = utils.userInfo();
      const params = { ...payload, unitCd };
      // console.log('GET_AED_LIST params >>>>', url, params);
      const list = yield call(ApiService.post, url, params);

      if (list) {
        yield put({ type: SET_DEVICE_STATUS_CNT, data: list.data });
      }
    } catch (err) {
      yield put(showAuthMessage(err));
    }
  });
}

export function* callUpcommingList() {
  yield takeEvery(GET_UPCOMMING_LIST, function* ({ payload }) {
    try {
      const url = `/aed/upcommingList`;
      const { unitCd } = utils.userInfo();
      const params = { ...payload, unitCd };
      const list = yield call(ApiService.post, url, params);
      if (list) {
        yield put({ type: SET_UPCOMMING_LIST, data: list.data });
      }
    } catch (err) {
      yield put(showAuthMessage(err));
    }
  });
}

export function* callStatusNow() {
  yield takeEvery(GET_STATUS_NOW, function* ({ payload }) {
    try {
      const url = `/aed/statusNow`;
      const { unitCd } = utils.userInfo();
      const params = { ...payload, unitCd };
      const list = yield call(ApiService.post, url, params);
      if (list) {
        yield put({ type: SET_STATUS_NOW, data: list.data });
      }
    } catch (err) {
      yield put(showAuthMessage(err));
    }
  });
}

export function* callStatusMonthly() {
  yield takeEvery(GET_STATUS_MONTHLY, function* ({ payload }) {
    try {
      const url = `/aed/errDeviceMonthly`;
      const { unitCd } = utils.userInfo();
      const params = { ...payload, unitCd };
      const list = yield call(ApiService.post, url, params);
      if (list) {
        yield put({ type: SET_STATUS_MONTHLY, data: list.data });
      }
    } catch (err) {
      yield put(showAuthMessage(err));
    }
  });
}

export function* importExcelAed() {
  yield takeEvery(GET_IMPORT_EXCEL_AED, function* ({ payload, params }) {
    try {
      console.log('importExcelAed payload >>>>', payload, params);
      const url = '/aed/excelUpload';
      const response = yield call(ApiService.excelFileUpload, url, payload);
      console.log('response >>>>>', response);

      if (!!response && response.statusCode === 'S00') {
        console.log('params >>>>>', params);
        yield put({ type: SET_AED_LIST, data: [] });
        yield put({ type: GET_AED_LIST, payload: params });
        utils.openNotificationWithIcon(
          'success',
          appIntl().formatMessage({ id: 'message.account.0000009' })
        );
      } else {
        Modal.error({
          title: 'error',
          width: 480,
          content: (
            <>
              <pre
                style={{
                  fontFamily: 'Roboto, -apple-system, BlinkMacSystemFont',
                }}
              >
                {response.statusMsg}
              </pre>
            </>
          ),
        });
      }
    } catch (err) {
      console.error('err >>>>>', err);
      yield put(showAuthMessage(err));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(callAedList),
    fork(callAedDetail),
    fork(callInsertAed),
    fork(callUpdateAed),
    fork(callDeleteAed),
    fork(callApprList),
    fork(callApprDetail),
    fork(callSaveAppr),
    fork(callAedHisList),
    fork(callDeviceStatusCnt),
    fork(callUpcommingList),
    fork(callStatusNow),
    fork(callStatusMonthly),
    fork(importExcelAed),
  ]);
}
