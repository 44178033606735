import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import {
  GET_USER_LIST,
  SET_USER_LIST,
  GET_USER_DETAIL,
  UPDATE_USER,
  DELETE_USER,
  UPDATE_PWD,
} from '../constants/Account';
import { getUserList, setUserList, setUserDetail } from '../actions/Account';
import { showAuthMessage } from '../actions/Auth';
import { appIntl } from 'IntlGlobalProvider';

import ApiService from 'services/ApiService';
import utils from 'utils';

export function* callUserList() {
  yield takeEvery(GET_USER_LIST, function* ({ payload }) {
    const url = '/user/list';
    const { unitCd } = JSON.parse(localStorage.getItem('auth_user'));
    const params = {
      ...payload,
      unitCd,
    };
    try {
      const list = yield call(ApiService.post, url, params);
      if (list) {
        yield put({ type: SET_USER_LIST, data: list.data });
      }
    } catch (err) {
      yield put(showAuthMessage(err));
    }
  });
}

export function* callUserDetail() {
  yield takeEvery(GET_USER_DETAIL, function* ({ payload }) {
    const url = `/user/list/${payload}`;

    try {
      const list = yield call(ApiService.get, url, {});
      if (list) {
        yield put(setUserDetail(list.data));
      }
    } catch (err) {
      yield put(showAuthMessage(err));
    }
  });
}

export function* callUpdateUser() {
  yield takeEvery(UPDATE_USER, function* ({ payload }) {
    try {
      const { userId } = payload;
      const url = `/user/${userId}`;
      const res = yield call(ApiService.patch, url, payload);
      if (res.resultCode === 201) {
        utils.openNotificationWithIcon(
          'success',
          appIntl().formatMessage({ id: 'message.account.0000009' })
        );
        yield put(getUserList());
      }
    } catch (err) {
      yield put(showAuthMessage(err));
    }
  });
}

export function* callDeleteUser() {
  yield takeEvery(DELETE_USER, function* ({ payload }) {
    try {
      const { userId } = payload;
      const url = '/user/' + userId;
      const params = {};
      const res = yield call(ApiService.delete, url, params);
      if (res.resultCode === 201) {
        utils.openNotificationWithIcon(
          'success',
          appIntl().formatMessage({ id: 'message.account.0000013' })
        );
        yield put(getUserList());
      }
    } catch (err) {
      yield put(showAuthMessage(err));
    }
  });
}

export function* callUpdatePwd() {
  yield takeEvery(UPDATE_PWD, function* ({ payload }) {
    try {
      const url = '/user/upPass';
      const params = payload;
      const res = yield call(ApiService.post, url, params);
      if (res.resultCode === 201) {
        utils.openNotificationWithIcon(
          'success',
          appIntl().formatMessage({ id: 'message.account.0000008' })
        );
        // yield put(getUserList());
      }
    } catch (err) {
      yield put(showAuthMessage(err));
    }
  });
}
export default function* rootSaga() {
  yield all([
    fork(callUserList),
    fork(callUserDetail),
    fork(callUpdateUser),
    fork(callDeleteUser),
    fork(callUpdatePwd),
  ]);
}
