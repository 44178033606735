/** 회원 권한 */
export const ROLE_INFO = [
  // { roleCd: 'SA', roleNm: 'System Adminstrator' },
  { roleCd: 'SM', roleNm: 'Adminstrator' },
  { roleCd: 'SU', roleNm: 'User' },
  { roleCd: 'SI', roleNm: 'Installer' },
  // { roleCd: 'GU', roleNm: 'General' },
];

/** 회원 승인상태 */
export const APPR_STATUS = [
  { status: 'I', statusNm: 'wating for confirm' },
  { status: 'S', statusNm: 'inactive' },
  { status: 'A', statusNm: 'active' },
  //   { status: 'R', statusNm: 'reject' },
];

/** 설치상태 */
export const ISTALL_STATUS = [
  { status: 'R', statusNm: 'registered' },
  { status: 'I', statusNm: 'installed' },
  { status: 'U', statusNm: 'uninstalled' },
];

/** 작업구분 */
export const JOB_TYPE = [
  { status: 'R', statusNm: 'registered' },
  { status: 'I', statusNm: 'installed' },
  { status: 'M', statusNm: 'modified' },
  { status: 'C', statusNm: 'checked' },
  { status: 'U', statusNm: 'uninstalled' },
];

/** 장비 서비스상태 */
export const SERVICE_STATUS = [
  { status: '0x00', statusNm: 'OK', color: '#70ce78' },
  { status: '0x01', statusNm: 'OK', color: '#70ce78' },
  { status: '0x02', statusNm: 'Warn(Minor)', color: '#FF761E' },
  { status: '0x03', statusNm: 'Warn(Major)', color: '#FF761E' },
  { status: '0x04', statusNm: 'Error', color: '#D53D3D' },
];

/** 장비옵션체크 */
export const DEVICE_OPTION_CHECK = [
  { status: '0x00', statusNm: 'OFF' },
  { status: '0x01', statusNm: 'ON' },
];

/** 장비상태 */
export const DEVICE_STATUS = [
  { status: '0x00', statusNm: 'Low' },
  { status: '0x01', statusNm: 'Middle' },
  { status: '0x02', statusNm: 'High' },
];
