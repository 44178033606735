import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';
import FileDownload from 'js-file-download';
import moment from 'moment';
import {
  GET_LIST_LANG,
  INSERT_LANG,
  UPDATE_LANG,
  DELETE_LANG,
  GET_LIST_TERMS,
  SAVE_TERMS,
  EXPORT_EXCEL,
  GET_IMPORT_EXCEL,
} from '../constants/Lang';
import {
  getListLang,
  setListLang,
  getListTerms,
  setListTerms,
  setImportExcel,
} from '../actions/Lang';
import { showAuthMessage } from '../actions/Auth';
import { appIntl } from 'IntlGlobalProvider';

import ApiService from 'services/ApiService';
import utils from 'utils';

export function* callListLang() {
  yield takeEvery(GET_LIST_LANG, function* ({ payload }) {
    const url = '/lang/list';

    try {
      const list = yield call(ApiService.get, url, {});
      if (list) {
        console.log('list >>>>>', list);
        yield put(setListLang(list.data));
      }
    } catch (err) {
      yield put(showAuthMessage(err));
    }
  });
}

export function* callInsertLang() {
  yield takeEvery(INSERT_LANG, function* ({ payload }) {
    try {
      const { langNm } = payload;
      const url = '/lang';
      const params = { langNm: langNm };
      const res = yield call(ApiService.post, url, params);

      if (res.statusCode === 201) {
        utils.openNotificationWithIcon(
          'success',
          appIntl().formatMessage({ id: 'message.account.0000009' })
        );
        yield put(getListLang());
      }
    } catch (err) {
      yield put(showAuthMessage(err));
    }
  });
}

export function* callUpdateLang() {
  yield takeEvery(UPDATE_LANG, function* ({ payload }) {
    try {
      const { langCd, useYn } = payload;
      const url = '/lang/' + langCd;
      const params = { useYn: useYn };
      const res = yield call(ApiService.patch, url, params);
      console.log('res >>>>>', res);
      if (res.statusCode === 201) {
        utils.openNotificationWithIcon(
          'success',
          appIntl().formatMessage({ id: 'message.account.0000009' })
        );
        yield put(getListLang());
      }
    } catch (err) {
      yield put(showAuthMessage(err));
    }
  });
}

export function* callDeleteLang() {
  yield takeEvery(DELETE_LANG, function* ({ payload }) {
    try {
      const { langCd } = payload;
      const url = '/lang/' + langCd;
      const params = {};
      const res = yield call(ApiService.delete, url, params);
      console.log('res >>>>>', res);
      if (res.statusCode === 201) {
        utils.openNotificationWithIcon(
          'success',
          appIntl().formatMessage({ id: 'message.account.0000013' })
        );
        yield put(getListLang());
      }
    } catch (err) {
      yield put(showAuthMessage(err));
    }
  });
}

export function* callListTerms() {
  yield takeEvery(GET_LIST_TERMS, function* ({ payload }) {
    try {
      console.log('callListTerms >>>>>>', payload);
      const { langCd } = payload;
      const url = '/term/list';
      const params = { langCd: langCd };
      const list = yield call(ApiService.post, url, params);
      if (list) {
        yield put(setListTerms(list.data));
      }
    } catch (err) {
      yield put(showAuthMessage(err));
    }
  });
}

export function* callSaveTerms() {
  yield takeEvery(SAVE_TERMS, function* ({ payload }) {
    try {
      const url = '/term/all';
      const res = yield call(ApiService.post, url, payload);

      if (res.statusCode === 201) {
        utils.openNotificationWithIcon(
          'success',
          appIntl().formatMessage({ id: 'message.account.0000009' })
        );
        const curLang = yield select((state) => state.lang.curLang) || '';
        yield put(getListTerms(curLang));
      }
    } catch (err) {
      yield put(showAuthMessage(err));
    }
  });
}

export function* exportExcelTerms() {
  yield takeEvery(EXPORT_EXCEL, function* ({ payload }) {
    try {
      console.log('exportExcelTerms payload >>>>', payload);
      // const { langCd, langNm } = payload;
      const url = '/term/excelDown';
      // const params = { langCd, langNm };
      const response = yield call(ApiService.fileDown, url, payload);
      console.log('response >>>>>', response);
      FileDownload(response, `Terms_${moment().format('YYMMDDHHmmss')}.csv`);
    } catch (err) {
      yield put(showAuthMessage(err));
    }
  });
}

export function* importExcelTerms() {
  yield takeEvery(GET_IMPORT_EXCEL, function* ({ payload }) {
    try {
      // console.log('exportExcelTerms payload >>>>', payload);
      const url = '/term/excelUpload';
      const response = yield call(ApiService.excelFileUpload, url, payload);

      if (response) {
        yield put(setImportExcel(response.data));
      }

      console.log('response >>>>>', response);
    } catch (err) {
      yield put(showAuthMessage(err));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(callListLang),
    fork(callInsertLang),
    fork(callUpdateLang),
    fork(callDeleteLang),
    fork(callListTerms),
    fork(callSaveTerms),
    fork(exportExcelTerms),
    fork(importExcelTerms),
  ]);
}
