import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import FileDownload from 'js-file-download';
import moment from 'moment';
import {
  GET_DEVICE_EV_LIST,
  SET_DEVICE_EV_LIST,
  GET_EVENT_LIST,
  SET_EVENT_LIST,
  GET_EVENT_DETAIL,
  GET_EV_LIST_EXCEL_DOWN,
  GET_EV_LIST_ALL_EXCEL_DOWN,
} from '../constants/Event';
import { setEventDetail } from '../actions/Event';
import { showAuthMessage } from '../actions/Auth';
import utils from 'utils';

import ApiService from 'services/ApiService';
import JSZip from 'jszip';
import { API_BASE_URL } from 'configs/AppConfig';

export function* callDeviceEvList() {
  yield takeEvery(GET_DEVICE_EV_LIST, function* ({ payload }) {
    const url = '/event/list';
    const { unitCd } = utils.userInfo();
    const params = { ...payload, unitCd };
    try {
      const list = yield call(ApiService.post, url, params);
      if (list) {
        yield put({ type: SET_DEVICE_EV_LIST, data: list.data });
      } else {
        yield put({ type: SET_DEVICE_EV_LIST, data: [] });
      }
    } catch (err) {
      yield put(showAuthMessage(err));
    }
  });
}

export function* callEventList() {
  yield takeEvery(GET_EVENT_LIST, function* ({ payload }) {
    const url = '/event/eventList';
    const params = {
      serialNo: payload.serialNo,
    };
    try {
      const list = yield call(ApiService.post, url, params);
      console.log('list >>>>', list);
      if (list) {
        yield put({ type: SET_EVENT_LIST, data: list.data });
      } else {
        yield put({ type: SET_EVENT_LIST, data: [] });
      }
    } catch (err) {
      yield put(showAuthMessage(err));
    }
  });
}

export function* exportExcelEventList() {
  yield takeEvery(GET_EV_LIST_EXCEL_DOWN, function* ({ payload }) {
    try {
      //console.log('exportExcelTerms payload >>>>', payload);
      const url = '/event/excelDeviceEvent';
      const res = yield call(ApiService.post, url, payload);
      if (res && res.resultCode === 200) {
        var link = document.createElement('a');
        link.href = `${API_BASE_URL}/${res.path}`;
        link.download = `EventList_${moment().format('YYMMDDHHmmss')}.zip`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (err) {
      yield put(showAuthMessage(err));
    }
  });
}

export function* exportExcelEventAllList() {
  yield takeEvery(GET_EV_LIST_ALL_EXCEL_DOWN, function* ({ payload }) {
    try {
      //console.log('exportExcelTerms payload >>>>', payload);
      const url = '/event/excelEventAll';
      const { unitCd } = utils.userInfo();
      const params = { ...payload, unitCd };
      const res = yield call(ApiService.post, url, params);
      console.log('response >>>>>', res);
      if (res && res.resultCode === 200) {
        var link = document.createElement('a');
        link.href = `${API_BASE_URL}/${res.path}`;
        link.download = `EventAllList_${moment().format('YYMMDDHHmmss')}.zip`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (err) {
      yield put(showAuthMessage(err));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(callEventList),
    fork(callDeviceEvList),
    fork(exportExcelEventList),
    fork(exportExcelEventAllList),
  ]);
}
