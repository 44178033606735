import fetch from 'auth/FetchInterceptor';

const JwtAuthService = {};

JwtAuthService.login = function (data) {
  return fetch({
    url: '/api/auth/login',
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: data,
  });
};

JwtAuthService.signUp = function (data) {
  return fetch({
    url: '/user',
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: data,
  });
};

JwtAuthService.dupCheck = function (id) {
  return fetch({
    url: '/user/dupCheck',
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: {},
  });
};

export default JwtAuthService;
