import {
  GET_DEVICE_EV_LIST,
  SET_DEVICE_EV_LIST,
  GET_EVENT_LIST,
  SET_EVENT_LIST,
  SET_SELECTED_SN,
  GET_EVENT_DETAIL,
  SET_EVENT_DETAIL,
  GET_EV_LIST_EXCEL_DOWN,
  GET_EV_LIST_ALL_EXCEL_DOWN,
} from '../constants/Event';

export const getDeviceEvList = (data) => {
  return {
    type: GET_DEVICE_EV_LIST,
    payload: data,
  };
};

export const setDeviceEvList = (data) => {
  return {
    type: SET_DEVICE_EV_LIST,
    payload: data,
  };
};

export const getEventList = (data) => {
  return {
    type: GET_EVENT_LIST,
    payload: data,
  };
};

export const setEventList = (data) => {
  return {
    type: SET_EVENT_LIST,
    payload: data,
  };
};

export const setSelectedSn = (data) => {
  return {
    type: SET_SELECTED_SN,
    data,
  };
};

export const getEventDetail = (data) => {
  return {
    type: GET_EVENT_DETAIL,
    payload: data,
  };
};

export const setEventDetail = (data) => {
  return {
    type: SET_EVENT_DETAIL,
    payload: data,
  };
};

export const getEventListExcelDown = (data) => {
  return {
    type: GET_EV_LIST_EXCEL_DOWN,
    payload: data,
  };
};

export const getEventAllListExcelDown = (data) => {
  return {
    type: GET_EV_LIST_ALL_EXCEL_DOWN,
    payload: data,
  };
};
