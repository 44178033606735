import React from 'react';
import {
  SIDE_NAV_WIDTH,
  SIDE_NAV_COLLAPSED_WIDTH,
  NAV_TYPE_TOP,
} from 'constants/ThemeConstant';
import { APP_NAME } from 'configs/AppConfig';
import { connect } from 'react-redux';
import utils from 'utils';
import { Grid } from 'antd';
import { Link } from 'react-router-dom';
import { APP_PREFIX_PATH } from 'configs/AppConfig';

const { useBreakpoint } = Grid;

const getLogoWidthGutter = (props, isMobile) => {
  const { navCollapsed, navType } = props;
  const isNavTop = navType === NAV_TYPE_TOP ? true : false;
  if (isMobile && !props.mobileLogo) {
    return 0;
  }
  if (isNavTop) {
    return '300px';
  }
  if (navCollapsed) {
    return `${SIDE_NAV_COLLAPSED_WIDTH}px`;
  } else {
    return `${SIDE_NAV_WIDTH}px`;
  }
};

const getLogo = (props) => {
  const { navCollapsed, logoType } = props;
  if (logoType === 'light') {
    if (navCollapsed) {
      return '/img/logo-sm-white.png';
    }
    return '/img/logo-white.png';
  }

  if (navCollapsed) {
    return '/img/logo-sm.png';
  }
  return '/img/logo.jpg';
};

const getLogoDisplay = (isMobile, mobileLogo) => {
  if (isMobile && !mobileLogo) {
    return 'd-none';
  } else {
    return 'logo';
  }
};

export const Logo = (props) => {
  const isMobile = !utils.getBreakPoint(useBreakpoint()).includes('lg');
  const auth = JSON.parse(localStorage.getItem('auth_user'));
  const unitNm = auth ? auth.unitNm : 'Mediana';
  const unitTelNo = auth ? auth.unitTelNo : '';

  const handleLogo = () => {
    console.log('handleLogo >>>>');
    if (props.onClose) {
      props.onClose();
    }
  };

  return (
    <div
      className={getLogoDisplay(isMobile, props.mobileLogo)}
      // style={{ width: `${getLogoWidthGutter(props, isMobile)}` }}
    >
      {/* <img
        src={getLogo(props)}
        alt={`${APP_NAME} logo`}
        style={{ width: "160px" }}
      /> */}
      <div className="topUnitWrap" onClick={() => handleLogo()}>
        <Link to={`${APP_PREFIX_PATH}/home`}>{unitNm}</Link>
        <span className="topUnitTel"> {unitTelNo}</span>
      </div>
    </div>
  );
};

const mapStateToProps = ({ theme }) => {
  const { navCollapsed, navType } = theme;
  return { navCollapsed, navType };
};

export default connect(mapStateToProps, null)(Logo);
