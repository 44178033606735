import {
  GET_USER_LIST,
  SET_USER_LIST,
  SET_SELECTED_USER,
  GET_USER_DETAIL,
  SET_USER_DETAIL,
  UPDATE_USER,
  DELETE_USER,
  UPDATE_PWD,
} from '../constants/Account';

export const getUserList = (data) => {
  return {
    type: GET_USER_LIST,
    payload: data,
  };
};

export const setUserList = (data) => {
  return {
    type: SET_USER_LIST,
    payload: data,
  };
};

export const setSelectedUser = (data) => {
  return {
    type: SET_SELECTED_USER,
    data,
  };
};

export const getUserDetail = (data) => {
  return {
    type: GET_USER_DETAIL,
    payload: data,
  };
};

export const setUserDetail = (data) => {
  return {
    type: SET_USER_DETAIL,
    payload: data,
  };
};
export const updateUser = (data) => {
  return {
    type: UPDATE_USER,
    payload: data,
  };
};

export const deleteUser = (data) => {
  return {
    type: DELETE_USER,
    payload: data,
  };
};

export const updatePwd = (data) => {
  return {
    type: UPDATE_PWD,
    payload: data,
  };
};
