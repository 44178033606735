import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import {
  GET_BATTERY_LIST,
  SET_BATTERY_LIST,
  GET_BATTERY_DETAIL,
  INSERT_BATTERY,
  UPDATE_BATTERY,
  DELETE_BATTERY,
} from '../constants/Battery';
import {
  getBatteryList,
  setBatteryList,
  setBatteryDetail,
} from '../actions/Battery';
import { showAuthMessage } from '../actions/Auth';
import { appIntl } from 'IntlGlobalProvider';

import ApiService from 'services/ApiService';
import utils from 'utils';

export function* callBatteryList() {
  yield takeEvery(GET_BATTERY_LIST, function* ({ payload }) {
    const url = '/battery/list';
    try {
      const { unitCd } = utils.userInfo();
      const params = { ...payload, unitCd };
      const list = yield call(ApiService.post, url, params);
      if (list) {
        yield put({ type: SET_BATTERY_LIST, data: list.data });
      }
    } catch (err) {
      yield put(showAuthMessage(err));
    }
  });
}

export function* callBatteryDetail() {
  yield takeEvery(GET_BATTERY_DETAIL, function* ({ payload }) {
    const url = `/battery/list/${payload}`;

    try {
      const list = yield call(ApiService.get, url, {});
      if (list) {
        yield put(setBatteryDetail(list.data));
      }
    } catch (err) {
      yield put(showAuthMessage(err));
    }
  });
}

export function* callInsertBattery() {
  yield takeEvery(INSERT_BATTERY, function* ({ payload }) {
    try {
      const url = `/battery`;
      const res = yield call(ApiService.post, url, payload);
      if (res.resultCode === 201) {
        utils.openNotificationWithIcon(
          'success',
          appIntl().formatMessage({ id: 'message.account.0000009' })
        );
        yield put(getBatteryList());
      }
    } catch (err) {
      yield put(showAuthMessage(err));
    }
  });
}

export function* callUpdateBattery() {
  yield takeEvery(UPDATE_BATTERY, function* ({ payload }) {
    try {
      const { batSeq } = payload;
      const url = `/battery/${batSeq}`;
      const res = yield call(ApiService.patch, url, payload);
      console.log('res >>>', res);
      if (res.resultCode === 201) {
        utils.openNotificationWithIcon(
          'success',
          appIntl().formatMessage({ id: 'message.account.0000009' })
        );
        yield put(getBatteryList());
      }
    } catch (err) {
      yield put(showAuthMessage(err));
    }
  });
}

export function* callDeleteBattery() {
  yield takeEvery(DELETE_BATTERY, function* ({ payload }) {
    try {
      const { batSeq } = payload;
      const url = '/battery/' + batSeq;
      const params = {};
      const res = yield call(ApiService.delete, url, params);
      if (res.resultCode === 201) {
        utils.openNotificationWithIcon(
          'success',
          appIntl().formatMessage({ id: 'message.account.0000013' })
        );
        yield put(getBatteryList());
      }
    } catch (err) {
      yield put(showAuthMessage(err));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(callBatteryList),
    fork(callBatteryDetail),
    fork(callInsertBattery),
    fork(callUpdateBattery),
    fork(callDeleteBattery),
  ]);
}
